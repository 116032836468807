
.calendar-timeline {
	position: relative;
	z-index: 1;
	* {
		box-sizing: content-box;
	}
	> table {
		position: relative;
		z-index: 1;
	}
	table {
		width: 100%;
		box-sizing: border-box;
		table-layout: fixed;
		border-collapse: collapse;
		border-spacing: 0;
		font-size: 1em;
	}
	tr, td {
		white-space: nowrap;
	}
	.widget-header {
		padding: 0;
		background: #fff;
	}
	.widget-content, .widget-content-compare {
		padding: 0;
	}

	.source-area {
		width: 360px;
		background: #f7f5f8;
		padding: 0 10px;
		border: none;
	}
	.time-area {
		.scroller-rows {
			position: relative;
			z-index: 3;
		}
		> table {
			border-bottom-style: none;
		}
	}

	.cell-content {
		display: grid;
		width: 100%;
		&.active {
			background-color: #804180;
			border-radius: 10px;
			color: white !important;
		}
	}
	.scroller-clip {
		overflow: hidden;
		position: relative;
		&.h-100 {
			height: 95px;
		}

		.scroller {
			.scroller-canvas {
				position: relative;
				box-sizing: border-box;
				min-height: 100%;
				> .scroller-content {
					z-index: 2;
					position: relative;
					border-style: solid;
					border-width: 0;
				}
				.cell-content {
					overflow: visible;
				}
			}
		}
	}
	.scroller-content {
		.widget-header {
			border-right: 1px dotted #c9d3dd;
			background: #fff;
			//min-width: 79px;
		}
		.widget-content {
			border-right: 1px dotted #c9d3dd;
			height: 40px;
			line-height: 0;
		}
		.widget-content-compare {
			border-right: 1px dotted #c9d3dd;
			vertical-align: baseline;
			height: 80px;
			line-height: 0;
		}
		.widget-bg-active {
			background-color: #D8C7DE;
			height: 100%;
			position: relative;
			box-shadow: 0 1px 0 #D8C7DE;
		}
	}
	.widget-header, .widget-content, .widget-content-compare {border-bottom-width: 0!important;}
	.source-area .widget-content, .source-area .widget-content-compare {
		border-bottom: 1px solid rgba(85,85,85,.15)!important;
	}
	.source-col {
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 5px;
		&.with-border {
			border-right: 2px solid rgba(85,85,85,.5);
		}
	}
	.date1 {
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 5px;
	}
	.date2 {
		font-size: 12px;
		font-weight: 400;
		display: inline-block;
		margin-top: 2px;
		color: #88888a;
		width: 100%;
		&.active {
			color: white;
		}
	}
	.source {
		height: 40px;
		align-items: center;
		&.flag {
			&:before {
				height: 40px;
			}
		}
	}
	.source-compare {
		height: 80px;
		align-items: baseline;
		.text-compare {
			padding-top: 20px;
		}
		&.flag {
			&:before {
				height: 80px;
			}
		}
	}
	.source, .source-compare {
		position: relative;
		display: flex;
		justify-content: center;
		font-size: 14px;
		font-weight: 400;
		cursor: pointer;
		&.flag {
			&:before {
				content: '';
				display: block;
				width: 6px;
				border-radius: 10px;
				background: #804180;
				position: absolute;
				left: 0;
				top: 0;
			}
		}
		&.border {
			border-right: 2px solid rgba(85,85,85,.5);
		}
		&.active {
			font-weight: bold;
		}
		button {
			position: absolute;
			display: flex;
			right: 0;
			border:none;
			outline: none;
			cursor: pointer;
			padding: 8px
		}
	}
	.timeline-row {
		border-bottom: transparent solid 1px;

		.widget-content, .widget-content-compare {
			position: relative;
		}
		.timeline-drag {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0px;

			z-index: 1;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			width: 100vw;
			height: 40px;
		}
	}


	.time-area.widget-header {
		.scroller-content table{
			//width: auto;
		}
	}

	.indiana-scroll-container::-webkit-scrollbar {
		scrollbar-width: 12px!important;
		width: 0px!important;
	}

    .scroller::-webkit-scrollbar {
      scrollbar-width: 12px!important;
      width: 0px!important;
    }
}

