body {
	margin: 0;
	padding: 0;
	font-family: sans-serif;
}

body.login-content {
	height: 100vh;
}

.login-content div#root {
	height: 100%;
}

a {
	text-decoration: none;
	color: black;
}

.login-button {
	background: #FF8F00;
	color: white;
	width: 231px;
}


body.login-content {
	background-image: url('/img/login/loginBackground.jpg');
	background-size: cover;
	background-position: center;
}

body.login-content-mobile {
	background-image: url('/img/login/loginBackground_mobile.jpg');
}

.login-content .signGoogle button {
	font-family: 'The Sans Veolia', sans-serif !important;
	font-size: 12px !important;
	max-width: 180px !important;
	cursor: pointer;
	background-color: #804180 !important;
	color: white !important;
	box-shadow: unset !important;
	border-radius: 10px;
}

.login-content .signGoogle button div {
	display: none;
}

.login-content .signGoogle button span {
	padding: 10px !important;
}
